import _ from "lodash";
import { inWords, inWords2 } from "../../utils";

export const invoiceTemplate = (data) => {
    let {
        clientAddress,
        clientName,
        clientGSTNo,
        challanDate,
        items,
        bank,
        billCode,
        entityDetails,
        packagingCosts,
        packagingTaxPercents,
        isEligibleForIgst
    } = data;




    console.log(data, "items in template")

    let TotalPackagingCost = 0

    var igstPercent = ""
    var sgstPercent = ""
    var cgstPercent = ""
    var cgstAmount = ""
    var sgstAmount = ""
    var igstAmount = ""

    for (let i = 0; Number(i) < packagingCosts.length; Number(i++)) {
        TotalPackagingCost += packagingCosts[Number(i)]
    }
    console.log('summmmmm', TotalPackagingCost)

    let bankName = "SYNDICATE BANK",
        bankAccNo = "5015 1400 000 418",
        bankIFSC = "SYNB 000 5015",
        bankBranch = "Zaveri Bazar Kalbadevi Mumbai - 2";
    if (bank) {
        bankName = bank.bankName;
        bankAccNo = bank.acNo;
        bankIFSC = bank.ifscCode;
        bankBranch = bank.branch;
    }
    let totalAmountInWords = "",
        totalQuantity = 0,
        totalAmount = 0,
        totalAmountAfterTax = 0,
        roundOff = 0,
        cgstPer = items[0].taxPercent / 2,
        sgstPer = items[0].taxPercent / 2,
        igstPer = 0;

    let rows = "";
    items.map((o) => {
        let {
            amount,
            rate,
            nameL,
            nameR,
            quantity,
            fittingAmount,
            tint,
            discountAmount,
            discountPercent,
            hsnCode,
            taxPercent
        } = o;
        totalAmount = totalAmount + Number(amount);
        let name = nameL + " " + nameR;
        let hsn = "";

        if (isEligibleForIgst) {
            igstPercent = taxPercent
            sgstPercent = 0
            cgstPercent = 0
            cgstAmount = 0
            sgstAmount = 0
            igstAmount = (Number(taxPercent) / 100) * amount
        } else {
            igstPercent = 0
            cgstPercent = Number(taxPercent) / 2

            sgstPercent = Number(taxPercent) / 2
            cgstAmount = (Number(taxPercent) / 200) * amount
            sgstAmount = (Number(taxPercent) / 200) * amount
            igstAmount = 0
        }
        let item = {
            name,
            isEligibleForIgst: isEligibleForIgst,
            hsn: hsnCode,
            rate,
            amount: amount,
            taxPercent: taxPercent,
            quantity,
            cgstPercent: cgstPercent,
            cgst: cgstAmount,
            sgst: sgstAmount,
            sgstPercent: sgstPercent,
            igst: igstAmount,
            igstPercent: igstPercent,
            ec: Number(fittingAmount) + Number(tint),
            discount: discountAmount
                ? Number(discountAmount)
                : discountPercent
                    ? `${discountPercent}%`
                    : 0,
        };
        totalQuantity += Number(item.quantity);
        rows = rows + renderTableRow(item) + "\n";
    });
    let cgst = (cgstPer / 100) * totalAmount,
        sgst = (sgstPer / 100) * totalAmount,
        igst = "";
    let tax = (Number(cgstPer + sgstPer) / 100) * (totalAmount + TotalPackagingCost);

    totalAmountAfterTax = totalAmount + TotalPackagingCost + tax
    totalAmountInWords = inWords2(_.round(totalAmountAfterTax, 2).toFixed(0));

    return `<!DOCTYPE html>
<html lang="en">
<head>
    <title>Invoice</title>
    <meta charset="UTF-8">
    <style>
        .border {
            border-style: solid;
            border-width: 1px;
        }

        .center {
            text-align: center;
            align-items: center;
            justify-content: center;
        }

        .row {
            display: flex;
            flex-direction: row;
            flex: 1;
        }

        .space-between {
            align-items: center;
            justify-content: space-between;
        }

        h2 {
            margin: 4px;
        }

        h4 {
            margin: 4px;
        }

        h3 {
            margin: 10px;
        }

        h5 {
            margin: 1px;
        }

        p {
            font-size: 14px;
            margin: 1px;
        }

        table, th, td {
            font-weight: bold;
            font-size: 12px;
            border-collapse: collapse;
            border: 1px solid black;
        }
    </style>
</head>
<body >
<div style="height: 1000px; width: 824px;">
<div style="display:flex;flex:1;flex-direction:column;margin: 10px">
    <div class="center border" style="border-radius: 10px">
        <p style="font-weight:bold;">Subject To Mumbai Jurisdiction</p>
        <h2 style="color: red">${entityDetails?.name}</h2>
        <p style="font-weight:bold;color: blue">${entityDetails?.address}</p>
        <p style="font-weight:bold;color: blue">Tel: ${entityDetails?.phone
        }. GST No : ${entityDetails?.gst}</p>
    </div>
    <h3 class="center">INVOICE</h3>
    <div class="row border space-between" style="flex:0;padding: 4px">
        <div>
            <h5>M/S ${clientName}</h5>
            <p>${clientAddress}</p>
            <p>Buyer's GST No : ${clientGSTNo ? clientGSTNo : ""}</p>
        </div>
        <div>
        <p>Dt: ${challanDate}</p>
            <p>No: ${billCode}</p>
        </div>
    </div>
    <div style="display: flex;flex: 1;flex-direction: column;margin-top: 2px">
        <table style="width:100%;">
            <tr>
                <th style="width: 20%;text-align: left;padding-left: 10px">Description</th>
                <th style="width: 8%">HSN Code</th>
                <th style="width: 5%">Qty</th>
                <th style="width: 8%">Rate</th>
                <th style="width: 8%">Discount</th>
                <th style="width: 8%">Extra Cost</th>
                <th style="width: 8%">Amount</th>
                ${!isEligibleForIgst
            ?
            `
                    <th style="width: 5%">CGST %</th>
                    <th style="width: 5%">CGST Amt</th>
                    <th style="width: 5%">SGST %</th>
                    <th style="width: 5%">SGST Amt</th>
                    `
            :
            `
                    <th style="width: 5%">IGST %</th>
                    <th style="width: 5%">IGST Amt</th>
                    `

        }
             
            </tr>
            ${rows}
        </table>
    </div>
    <div >
        <table style="width:100%;flex:0">
            <tr>
                <th style="width: 31%;text-align: left;padding-left: 10px">
                    <h4>In Words <p style="font-weight: normal">${totalAmountInWords} Rupees Only</p></h4>
                </th>
                <th style="width: 4.5%">${totalQuantity}</th>
                <th style="width: 23%"></th>
                <th style="width: 9.5%">${_.round(totalAmount, 2).toFixed(
            2
        )}</th>
                <th style="width: 4.5%"></th>
                <th style="width: 4.5%">${_.round(cgst, 2).toFixed(2)}</th>
                <th style="width: 4.5%"></th>
                <th style="width: 4.5%">${_.round(sgst, 2).toFixed(2)}</th>
            </tr>
        </table>
        <div style="display: flex;flex-direction: row;flex:0;width: 100%">
            
            <div class="border" style="flex:1;padding: 4px">
                <p style="color: hotpink">Bank Payment Details</p>
                <p style="color: hotpink">Bank Name : ${bankName}</p>
                <p style="color: hotpink">A/C No : ${bankAccNo}</p>
                <p style="color: hotpink">IFSC Code : ${bankIFSC}</p>
                <p style="color: hotpink">Branch : ${bankBranch}</p>
             
            </div>
            <div class="border" style="flex:1;padding: 4px">
                <div style="display:flex;flex-direction:row;justify-content: space-between">
                    <h4>Invoice Value:</h4>
                    <p>${_.round(totalAmount, 2)}</p>
                </div>
                
                <div style="display:flex;flex-direction:row;justify-content: space-between">
                <h4>Total Packaging Cost</h4>
                <p>${_.round(TotalPackagingCost, 2)}</p>
                </div>

                <div style="display:flex;flex-direction:row;justify-content: space-between">
                    <h4>Taxable Value:</h4>
                    <p>${_.round(totalAmount + TotalPackagingCost, 2)}</p>
                </div>
                
                <div style="display:flex;flex-direction:row;justify-content: space-between">
                <h4>Total CGST</h4>
                <p>${_.round((totalAmountAfterTax - (totalAmount + TotalPackagingCost)) / 2)}</p>
                </div>

                <div style="display:flex;flex-direction:row;justify-content: space-between">
                <h4>Total SGST</h4>
                <p>${_.round((totalAmountAfterTax - (totalAmount + TotalPackagingCost)) / 2)}</p>
                </div>

                

            ${!isEligibleForIgst ? `
                
            ` : `

    
            `}
                <div style="display:flex;flex-direction:row;justify-content: space-between">
                    <h4>Grand Total:</h4>
                    <p>${_.round(totalAmountAfterTax, 2)}</p>
                </div>
            </div>
        </div>
        <div style="display:flex;flex-direction:row;justify-content: space-between">
            <h4>E & OE</h4>
            <h4 style="color: red">For ${entityDetails?.name}</h4>
        </div>
        <h5 style="margin-top:40px;color:blue;text-align: end">PROPIETOR</h5>
    </div>
</div>
</div>
</body>
</html>


`;
};

let renderTableRow = (item) => {
    let {
        name,
        hsn,
        rate,
        amount,
        quantity,
        cgstPercent,
        cgst,
        sgst,
        sgstPercent,
        igst,
        igstPercent,
        ec,
        discount,
        isEligibleForIgst
    } = item;
    return `<tr>
                <th style="width: 25%;text-align: left;padding-left: 10px">${name}</th>
                <th style="width: 5%">${hsn}</th>
                <th style="width: 5%">${quantity}</th>
                <th style="width: 8%">${rate}</th>
                <th style="width: 6%">${discount}</th>
                <th style="width: 6%">${ec}</th>
                <th style="width: 10%">${_.round(amount, 2).toFixed(2)}</th>
                ${!isEligibleForIgst ?
            ` <th style="width: 5%">${cgstPercent}</th>
                    <th style="width: 5%">${_.round(cgst, 2).toFixed(2)}</th>
                    <th style="width: 5%">${sgstPercent}</th>
                    <th style="width: 5%">${_.round(sgst, 2).toFixed(2)}</th>
                    ` : `
                    <th style="width: 5%">${igstPercent}</th>
                    <th style="width: 5%">${_.round(igst, 2).toFixed(2)}</th>
                    
                    `}
            </tr>`;
};
