import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import { Search, People } from "@material-ui/icons";
import axios from "axios";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CustomInput from "../../components/CustomInput/CustomInput";
import { baseUrl, GetAllProdLogAPI } from "../../config/api";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

const columns = [
  { id: "productCode", label: "Product Code" },
  { id: "productName", label: "Product Name" },
  {
    id: "price",
    label: "Price",
    format: (value) =>
      Number(value).toLocaleString("en-IN", {
        maximumFractionDigits: 2,
        style: "currency",
        currency: "INR",
      }),
  },
  { id: "quantity", label: "Quantity" },
  { id: "status", label: "Status" },
];

export default function ProdLogList() {
  const classes = useStyles();
  const authToken = useSelector((state) => state.userReducer.authToken);

  const [accounts, setAccounts] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    fetchProductLogs(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const fetchProductLogs = async (page, rowsPerPage) => {
    try {
      const skip = page * rowsPerPage;
      const query = `?limit=${rowsPerPage}&skip=${skip}`;

      const res = await axios.get(`${GetAllProdLogAPI()}${query}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      setAccounts(res?.data?.productLog || []);
      setTotal(res?.data?.total || 0);
    } catch (err) {
      console.error("Error fetching product logs: ", err);
      toast.error("Something went wrong, Please Contact Admin", {
        position: "top-center",
        autoClose: 1500,
      });
    }
  };

  const handleSearch = async () => {
    try {
      const filteredAccounts = accounts.filter((account) =>
        account.productName.toLowerCase().includes(search.toLowerCase())
      );
      setAccounts(filteredAccounts);
      setTotal(filteredAccounts.length);
    } catch (error) {
      console.error("Error searching data:", error);
    }
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <h4 className={classes.cardTitleWhite}>Product Logs</h4>
              <Box display="flex" alignItems="center">
                <CustomInput
                  id="search"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    style: { color: "white", marginRight: 10 },
                    placeholder: "Search",
                    value: search,
                    onChange: (e) => setSearch(e.target.value),
                    endAdornment: (
                      <InputAdornment position="end">
                        <People />
                      </InputAdornment>
                    ),
                  }}
                />
                <IconButton color="secondary" onClick={handleSearch}>
                  <Search />
                </IconButton>
                <IconButton aria-label="add" style={{ color: "white" }}>
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
          </CardHeader>
          <CardBody>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align="center">
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {accounts
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.productCode}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align="center">
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
