import {
    Box,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    IconButton,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Slide,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import Moment from "moment";
import { invoiceTemplate } from "../../assets/html/invoiceTemplate";
import { challan1Template } from "../../assets/html/challan1Template";
import { challan2Template } from "../../assets/html/challan2Template";
import { challan3Template } from "../../assets/html/challan3Template";
import { dataURItoBlob, htmlTemplateToDataString } from "../../utils";
import { CloudDownload, CloudUpload, Receipt } from "@material-ui/icons";
import AccountView from "../Account/AccountView";
import PrintIcon from "@mui/icons-material/Print";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import AddIcon from "@material-ui/icons/Add";
import axios from "axios";
import {
    DeleteChallanAPI,
    GetAllChallansAPI,
    GetAllPrintAdsAPI,
    GetEntityAPI,
    GetStockReportAPI,
} from "../../config/api";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { extendMoment } from "moment-range";
import { useSelector } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import Checkbox from "@material-ui/core/Checkbox";
import { toast } from "react-toastify";
import { ConfirmationDialog } from "../../components/common";
import { challan4Template } from "../../assets/html/challan4Template";
import StockListPageM from "../../assets/html/StockListPageM";

const moment = extendMoment(Moment);

const useStyles = makeStyles((theme) => ({
    fab: {
        position: "absolute",
        bottom: theme.spacing(-24),
        right: theme.spacing(2),
    },
    formControl: {
        paddingBottom: "10px",
        margin: "27px 0 0 0",
        position: "relative",
        verticalAlign: "unset",
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
}));


const columns = [
    { id: "productCode", label: "Code" },
    { id: "threshold", label: "Threshold" },
    { id: "quantity", label: "quantity" },
    { id: "order", label: "Order" },
];

export const StockList = (props) => {
    const classes = useStyles();

    const { accounts, filter, readyMasterRange, dataToPass, setDataaa } = props;
    const authToken = useSelector((state) => state.userReducer.authToken);
    const userInfo = useSelector((state) => state.userReducer.userInfo);
    const [challans, setChallans] = useState([]);
    const [openChallanAddDialog, setOpenChallanAddDialog] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [selectedItem, setSelectedItem] = useState(null);
    const [openAccountViewDialog, setOpenAccountViewDialog] = useState(null);
    const [showInvoiceSelectionDialog, setShowInvoiceSelectionDialog] = useState(false);
    const [openViewDialog, setOpenViewDialog] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(null);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openDCDialog, setOpenDCDialog] = useState(false);
    const [entityDetails, setEntityDetails] = useState({});
    const [tota, setTota] = useState(0)
    const [partyWithCashPreference, setPartyWithCashPreference] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [selectedChallan, setSelectedChallan] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])
    const { category, product, brand } = dataToPass
    console.log(isLoading, "kiki");
    const options = {
        format: 'A4',             // Set the paper format to A4
        orientation: 'portrait',  // Set the orientation to portrait (can also be 'landscape')
        margin: {
            top: '20px',
            right: '30px',
            bottom: '20px',
            left: '50px'
        }
    };
    async function handleSearch() {
        try {
            let query = `&limit=${rowsPerPage}&skip=${page}`;
            setIsLoading(true);

            const res = await axios.get(
                GetStockReportAPI(userInfo.entity) +
                `?type=${category}&productName=${product}&brand=${brand}${query}`,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            console.log("ressss", res?.data?.rms);
            setData(res.data.rms);
            setDataaa(res.data.rms)
            setTota(res.data.total)
            console.log(res.data.total, "ggg");
            setIsLoading(false);
        } catch (error) {
            if (error.response && error.response.status === 404) {
                toast.error("No data found");
                setIsLoading(false)
                return
            } else {
                toast.error("Failed to fetch data");
                setIsLoading(false)
                return
            }
            console.log(error, "fff");
        }
    }

    useEffect(() => {
        handleSearch()
    }, [rowsPerPage, page, dataToPass])


    const _getChallans = useMemo(
        () => async (page, rowsPerPage) => {
            let skipm = page;
            console.log("limit & skip", rowsPerPage, page, skipm);

            if (rowsPerPage === undefined || rowsPerPage === null || rowsPerPage === NaN) {
                rowsPerPage = 10;
            }

            if (skipm === undefined || skipm === null || skipm === NaN) {
                skipm = 0;
            }

            try {
                let query = `&isBilled=${false}&limit=${rowsPerPage}&skip=${skipm}&paymentStatus=${"Pending"}&status=${"Active"}`;
                let res = await axios.get(GetAllChallansAPI(userInfo.entity) + query, {
                    headers: { Authorization: `Bearer ${authToken}` },
                });

                setChallans(res.data.challans);
            } catch (err) {
                toast.error("Error occurred while fetching Transactions.");
            }
        },
        [authToken, userInfo.entity]
    );

    const _getEntity = useMemo(
        () => async () => {
            try {
                let res = await axios.get(GetEntityAPI(userInfo.entity), {
                    headers: { Authorization: `Bearer ${authToken}` },
                });
                setEntityDetails(res.data);
            } catch (e) {
                toast.error("Error occurred while fetching entities.");
            }
        },
        [authToken, userInfo.entity]
    );

    useEffect(() => {
        const fetchData = async () => {
            await _getChallans(page, rowsPerPage);
            await _getEntity();
        };
        fetchData();
    }, [openAddDialog, openEditDialog, openDCDialog, page, rowsPerPage, _getChallans, _getEntity]);

    async function _deleteItem(item) {
        try {
            await axios.delete(DeleteChallanAPI(item._id), {
                headers: { Authorization: `Bearer ${authToken}` },
            });
            toast.success("Successfully Deleted!", {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            _getChallans();
        } catch (e) {
            toast.error("Something went wrong, Please Contact Admin", {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleOpenInvoiceSelectionModal = (item) => {
        setSelectedItem(item);
        setShowInvoiceSelectionDialog(true);
    };



    const RenderTableRow = ({ row }) => {
        const [open, setOpen] = useState(false);

        return (
            <>
                <TableRow key={row.productCode}>
                    <TableCell>{row.productCode}</TableCell>
                    <TableCell>{row.threshold}</TableCell>
                    <TableCell>{row.quantity}</TableCell>
                    <TableCell>{row.threshold - row.quantity}</TableCell>
                </TableRow>
            </>
        );
    };

    return (
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell key={column.id}>{column.label}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        isLoading && (<p>Loading</p>)
                    }
                    {!isLoading && data.map((row) => (
                        <RenderTableRow key={row.productCode} row={row} />
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={tota}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
};
