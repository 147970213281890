import jsPDF from 'jspdf';
import 'jspdf-autotable';
import _ from 'lodash';
import moment from 'moment';

export const pointToPointTemplate = (data) => {
  let { challans, startDate, endDate } = data;
  console.log('da', data);



  let rows = [];
  let clientName = "";
  let entityId = "";
  let endAmt = 0;
  let endQty = 0;

  if (challans.length > 0) {
    clientName = challans[0].party.accountName;
    entityId = challans[0].entity;
  }
  let code = "";
  if (entityId === "62da46cf7eb6aa02db41297f") {
    code = `VO`;
  } else if (entityId === "62da46c57eb6aa02db41297d") {
    code = `IE`;
  } else {
    code = `VR`;
  }

  challans.forEach((o, index) => {
    let createdAt = o["createdAt"];
    let totalQuantity = 0;
    let totalAmount = 0;

    rows.push([
      '', `Created At: ${moment(createdAt).format("DD-MM-YYYY")}`, // Format createdAt as needed
      '', `${o.code}`, '', '', '', '', '', '', ''
    ]);

    o.items.forEach((i, id) => {
      let {
        quantity,
        codeR,
        codeL,
        nameL,
        nameR,
        rateR,
        rateL,
        discountPercent,
        discountAmount,
        fittingAmount,
        tint,
        orderRef,
        amount,
        packagingCost,
      } = i;
      console.log('i', o);

      totalQuantity += parseInt(quantity);
      totalAmount += parseFloat(amount);
      if (packagingCost) {
        totalAmount += parseInt(packagingCost);
      }
      endAmt += parseFloat(totalAmount);
      endQty += parseInt(quantity);
      let row = [
        id > 0 ? '' : index + 1,
        // nameR && codeR ? `${nameR}` : '',
        nameR && codeR ? `${nameR} (${codeR.slice(3)})` : '',
        nameL && codeL ? `${nameL} (${codeL.slice(3)})` : '',
        orderRef,
        quantity,
        rateR,
        rateL,
        discountPercent ? `${discountPercent}%` : discountAmount ? `Rs.${discountAmount}` : '',
        fittingAmount,
        tint,
        _.round(amount).toFixed(2)
      ];

      rows.push(row);

      if (id + 1 === o.items.length) {
        rows.push([
          '', '', '', 'Total', totalQuantity, '', '', '', '', '', _.round(totalAmount).toFixed(2), 'special']);
      }
    });
  });

  const options = {
    orientation: "p",
    unit: "pt",
    format: [850, 1066],
    putOnlyUsedFonts: true,
  };

  const doc = new jsPDF(options);


  doc.autoTable({
    startY: 50,
    head: [['Sr No.', 'Name R', 'Name L', 'Order Ref', 'Qty', 'Rate R', 'Rate L', 'Disc', 'Fitt Amt', 'Tint', 'Amount']],
    body: rows,
    styles: {
      fontSize: 8,
      cellPadding: 2,
      lineWidth: 0.1,
      lineColor: [0, 0, 0] // Black borders
    },
    theme: 'grid', // Grid format
    headStyles: {
      fillColor: [255, 255, 255], // White header background
      textColor: [0, 0, 0], // Black text color
      fontStyle: 'bold' // Bold text
    },
    bodyStyles: {
      fillColor: [255, 255, 255], // White body background
      textColor: [0, 0, 0] // Black text color
    },
    alternateRowStyles: {
      fillColor: [240, 240, 240] // Light grey for alternating rows
    },
    didDrawCell: function (data) {
      // Check if the row has 'special' in the last cell of the last row
      if (data.row.index === rows.length - 1 && data.column.index === 10 && data.cell.raw === 'special') {
        // Modify the border thickness
        doc.setLineWidth(1.5); // Set thicker line width for the border
        doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height); // Draw a rectangle around the cell
        doc.setLineWidth(0.1); // Reset to default line width for other cells
      }
    },
    didDrawPage: function (data) {
      if (data.pageCount === 1) {
        doc.setFontSize(15);
        doc.text('Challan Summary', 20, 25);
        doc.setFontSize(10);
        doc.text('Approval Memo', 300, 40);
        doc.text(code, 500, 40);
        if (startDate && endDate) {
          doc.text(`Date:${moment(startDate).format("DD-MM-YYYY")} to ${moment(endDate).format("DD-MM-YYYY")}`, 700, 40);
        }

        doc.text(`M/S: ${clientName}`, 20, 40);
      }
      // Footer
      let footerStr = `Page ${data.pageCount}`;
      doc.text(footerStr, data.settings.margin.left, doc.internal.pageSize.height - 10);
    }
  });

  // Total summary table
  const secondTableStartY = doc.lastAutoTable.finalY;
  doc.autoTable({
    showHead: "never",
    head: [['Sr No.', 'Name R', 'Name L', 'Order Ref', 'Qty', 'Rate R', 'Rate L', 'Disc', 'Fitt Amt', 'Tint', 'Amount']],
    body: [
      ['', '', '', '', '', 'E & OE', '', '', '', `Total Qty:${endQty}`, `Balance:${endAmt.toFixed(2)}`]
    ],
    startY: secondTableStartY,
    startX: 5,
    styles: {
      fontStyle: 'bold',
      fontSize: 8,
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      fillColor: [255, 255, 255]
    },
    headStyles: {
      fontStyle: 'bold',
      cellPadding: 0.75,
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      valign: 'middle',
      lineColor: [0, 0, 0],
      fontSize: 8,
    },
    footStyles: {
      cellPadding: 0.75,
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      lineWidth: 0.05,
      lineColor: [0, 0, 0]
    },
    tableLineWidth: 0.1,
    tableLineColor: [0, 0, 0],
    columnStyles: {
      10: { halign: 'right' }, // Right align the last column (index 5)
      4: { halign: 'left' }
    }
  });


  const pdfBlob = doc.output('blob');

  // Open the PDF in a new browser tab
  const url = window.URL.createObjectURL(pdfBlob);
  window.open(url, '_blank');

  // Uncomment to save the PDF directly
  // return doc.save('point_to_point_template.pdf');
};
