import React, { useEffect, useState } from "react";
import PouchDB from "pouchdb";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Backdrop,
  Snackbar,
  CircularProgress,
  makeStyles,
  TextField,
  Toolbar,
  Typography,
  Link,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Account,
  AccountPlus,
  Email,
  Lock,
  ViewDashboard,
} from "mdi-material-ui";
import PouchdbFind from "pouchdb-find";
import { loginSuccess, readyMasters } from "../../actions";
import { LoginAPI } from "../../config/api";

PouchDB.plugin(PouchdbFind);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  gradient: {
    background: "linear-gradient(87deg,#11cdef,#1171ef)",
    width: "100%",
    display: "flex",
    flex: 0.6,
    justifyContent: "center",
    // height: 400
  },
  cardContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    // position: 'absolute',
    top: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
    left: "50%",
    // transform: 'translate(-50%,-50%)'
  },
  card: {
    width: "20%",
    flexDirection: "column",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Login(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [alertObject, setAlertObject] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const _onLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let res = await axios.post(LoginAPI(), { email, password });
      setOpen(true);
      setAlertObject({ status: "success", message: "Login successful" });
      console.log(res.data.readyMasters, "These are my ready masters")

      dispatch(
        loginSuccess({
          userInfo: res.data.user,
          authToken: res.data.token,
        })
      );

      // dispatch(readyMasters({ readyMasters: res.data.readyMasters }));
      setTimeout(() => props.history.replace("/admin/dashboard"), 2000);
    } catch (err) {
      setLoading(false);
      setOpen(true);
      setAlertObject({ status: "error", message: err.response.data });
    }
  };

  return (
    <div className={classes.root}>
      <div
        style={{
          position: "absolute",
          zIndex: -1,
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <div className={classes.gradient}></div>
        <div
          style={{
            backgroundColor: "#172b4d",
            width: "100%",
            display: "flex",
            flex: 1,
            // height: 400
          }}
        />
      </div>

      <div className={classes.cardContainer}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>V.R ENTERPRISES</title>
        </Helmet>
        <Typography variant="h4" style={{ color: "white", margin: 40 }}>
          Welcome to Astra Lens !
        </Typography>
        <Card className={classes.card}>
          <form onSubmit={_onLogin}>
            <p style={{ textAlign: "center", color: "#adb5bd" }}>Sign in</p>
            <Box
              display={"flex"}
              backgroundColor={"red"}
              flexDirection={"column"}
              marginTop={4}
            >
              <TextField
                autoFocus
                className={classes.margin}
                placeholder={"Email"}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                id="input-with-icon-textfield"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email style={{ color: "#adb5bd" }} />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                className={classes.margin}
                id="input-with-icon-textfield"
                placeholder={"Password"}
                type={"password"}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock style={{ color: "#adb5bd" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Button
              type="submit"
              style={{ marginTop: 20, width: "100%" }}
              variant="contained"
              color="primary"
            >
              Sign In
            </Button>
          </form>
          <Link
            href="/forgot-password"
            variant="body2"
            style={{ marginTop: 20, textAlign: "left" }}
          >
            Forgot password?
          </Link>
        </Card>
      </div>
      {alertObject ? (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={alertObject.status}>
            {alertObject.message}
          </Alert>
        </Snackbar>
      ) : null}
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
    </div>
  );
}
