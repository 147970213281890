import React, { useEffect, useState } from "react";
// @material-ui/core components
import {
  Box,
  Dialog,
  Fab,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
} from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import axios from "axios";
import {
  CreateBankAPI,
  CreatePaymentAPI,
  GetAllAccountsAPI,
  GetAllBillsAPI,
  GetAllChallansAPI,
  UpdateBillAPI,
  UpdateChallanAPI,
  GetPartyPurchasesAPI,
  GetAllLabsAPI,
  GetAllOrdersAPI,
  CreateLabAPI,
  SendEmailAPI,
} from "../../config/api";
import { Autocomplete } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import _ from "lodash";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import moment from "moment";

const styles = {
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);
const columns = [
  { id: "party", label: "Party" },
  { id: "code", label: "Order code" },
  { id: "productName", label: "Product" },
  { id: "power", label: "Power" },
  { id: "orderAmount", label: "Order Amount" },



];


export default function SendEmail(props) {


  const classes = useStyles();
  const theme = useTheme();
  const userInfo = useSelector((state) => state.userReducer.userInfo);
  const authToken = useSelector((state) => state.userReducer.authToken);

  const orderStatus = "Pending"

  const { open, close, fetchData } = props;
  const [lab, setLab] = useState([]);
  const [selectedLab, setSelectedLab] = useState("")
  const [orders, setOrders] = useState([])
  const [orderLoaded, setOrderLoaded] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])

  console.log(selectedRows, "These are my selected rows")


  useEffect(() => {
    _getLabs();
    // _getOrders();
  }, []);

  async function _getLabs() {
    try {
      let res = await axios.get(GetAllLabsAPI(userInfo.entity)).then(res => setLab(res.data));
      console.log("RES", res.data)
      // setLabs(res.data.labs);
    } catch (e) {
      // setLabs([])
      // console.log(e);
      // toast.error(("Something went wrong, Please Contact Admin"), {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  }

  async function _getOrders() {
    try {
      let res = await axios.get(
        GetAllOrdersAPI(userInfo.entity) + `&lab=${selectedLab.labName}` + `&orderStatus=${orderStatus}` + `&isEmailSent=${false}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setOrders(res.data.orders);
      console.log("Reading orders", res.data.orders)
      setOrderLoaded(true)
      //   setOrdersGrouped(res.data.ordersGrouped);
    } catch (err) {
      // console.log("error:", err);
      // toast.error(("Something went wrong, Please Contact Admin"), {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  }

  async function _postEmail() {
    try {
      let selectedorders = orders.filter(order => selectedRows.includes(order._id))
      console.log(selectedorders, "These are my orders")

      let res = await axios.post(SendEmailAPI(), { selectedorders, selectedLab }, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      toast.success("Email Sent Successfully", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setEmailSent(true)
      setSelectedLab([])
      setSelectedRows([])
      setOrders([])
      setOrderLoaded(false)
      close();
    }
    catch (e) {
      // console.log(e)
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }



  let data = orders;

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      aria-labelledby="responsive-dialog-title"
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <h4 className={classes.cardTitleWhite}>Send Email</h4>
                <Box>
                  <Button color="primary" onClick={() => _getOrders()} >
                    Get Orders
                  </Button>
                  <Button color="primary" onClick={() => _postEmail()} disabled={emailSent} >
                    Send Email
                  </Button>
                </Box>
              </Box>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Autocomplete
                    id="party"
                    className={classes.formControl}
                    options={lab}
                    value={selectedLab}
                    onChange={(event, value) => setSelectedLab(value)}
                    getOptionLabel={(option) => option.labName}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Lab*" />
                    )}
                  />
                </GridItem>
              </GridContainer>

              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <Checkbox
                      style={{
                        color: "#512da8",
                      }}
                      onChange={(e) => {
                        let c = [];
                        if (e.target.checked) {
                          orders.map((i) => {
                            c.push(i._id);
                          });
                        }
                        setSelectedRows(c);
                      }}
                      checked={
                        selectedRows.length > 0 &&
                        orders.length === selectedRows.length
                      }
                      inputProps={{ "aria-labelledby": -1 }}
                    />
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={"center"}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>

                    ))}

                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              style={{
                                color: "#512da8",
                              }}
                              onChange={() => {
                                let copy = _.clone(selectedRows);
                                if (_.includes(copy, row._id)) {
                                  let index = _.indexOf(copy, row._id);
                                  copy.splice(index, 1);
                                } else {
                                  copy.push(row._id);
                                }
                                setSelectedRows(copy);
                              }}
                              checked={_.includes(selectedRows, row._id)}
                              inputProps={{ "aria-labelledby": row._id }}
                            />

                          </TableCell>
                          {columns.map((column) => {
                            let value = row[column.id];
                            if (column.id === "party") {
                              return (
                                <TableCell key={column.id} align={"center"}>
                                  {row.party.accountName}
                                </TableCell>
                              );
                            }
                            if (column.id === "code") {
                              return (
                                <TableCell key={column.id} align={"center"}>
                                  {row.code}
                                </TableCell>
                              );
                            }
                            if (column.id === "productName") {
                              return (
                                <TableCell key={column.id} align={"center"}>
                                  {row.orderDetail.productName}
                                </TableCell>
                              );
                            }
                            if (column.id === "power") {
                              return (
                                <TableCell key={column.id} align={"center"}>
                                  R - {row.orderDetail.rSph}{row.orderDetail.rCyl}/{row.orderDetail.rAxis}/{row.orderDetail.rAddition}
                                  <br />
                                  L - {row.orderDetail.lSph}{row.orderDetail.lCyl}/{row.orderDetail.lAxis}/{row.orderDetail.lAddition}
                                </TableCell>
                              );
                            }
                            if (column.id === "orderAmount") {
                              return (
                                <TableCell key={column.id} align={"center"}>
                                  {row.price}
                                </TableCell>
                              );
                            }
                          }
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </CardBody>
            {/* <CardFooter style={{ justifyContent: "center" }}>
              <Button color="primary" onClick={() => _getOrders()}>
                Get Orders
              </Button>
              <Button color="secondary" onClick={() => _postEmail()} >
                Send Email
              </Button>
            </CardFooter> */}
          </Card>
        </GridItem>
      </GridContainer>
      {/* <pre>
        {
            JSON.stringify(orders,null,2)
        }
        </pre> */}
    </Dialog>
  );
}
