import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import _ from "lodash";

let options = {
  orientation: "p",
  unit: "pt",
  format: [850, 1066],
  putOnlyUsedFonts: true,
};

export const ledgerTemplate = (data) => {

  let {
    ledgers,
    startDate,
    endDate,
    clientName,
    closingBalance,
    ledgerType,
    openingBalance,
  } = data;
  console.log("dataled", data);

  let tableData = [];
  let balance = openingBalance;

  console.log(ledgers, "<<<<<ledgers", tableData)

  ledgers.map((o, index) => {
    let { codes, amount, type, date } = o;
    if (type === "credit") {
      balance -= Number(amount);
    } else {
      balance += Number(amount);
    }
    let row = [
      index + 1,
      moment(date).format("DD-MM-YYYY"),
      codes[0],
      type === "credit" ? amount : "",
      type === "debit" ? amount : "",
      balance.toFixed(2),
    ];
    tableData.push(row);
  });

  let doc = new jsPDF(options);

  // doc.setFontSize(14);
  // doc.text(`Ledger - ${ledgerType}`, 40, 30);

  // if (startDate != null && endDate != null) {
  //     doc.text(`${moment(startDate).format("DD-MM-YYYY")} to ${moment(endDate).format("DD-MM-YYYY")}`, 600, 30);
  // }

  // doc.text(`M/S: ${clientName}`, 40, 50);
  // doc.text(`Opening Balance: ${openingBalance}`, 600, 50);

  doc.autoTable({
    startY: 70,
    margin: { top: 2 },
    head: [["Sr No.", "Date", "Codes", "Credit", "Debit", "Balance"]],
    body: [
      ["", "", "Opening Balance", "", "", `${openingBalance}`],
      ...tableData,
    ],
    styles: { fontSize: 12, cellPadding: 5 },
    theme: "striped", // Set the theme to striped
    alternateRowStyles: { fillColor: [240, 240, 240] }, // Light grey for alternating rows
    tableLineColor: [0, 0, 0], // Black borders
    tableLineWidth: 0.1, // Table border width
    headStyles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      fontStyle: "bold",
    }, // White header with black text
    didDrawPage: function (data) {
      if (data.pageCount === 1) {
        // Title and header information
        doc.setFontSize(14);
        doc.text(`Ledger - ${ledgerType}`, 40, 30);

        if (startDate != null && endDate != null) {
          doc.text(
            `${moment(startDate).format("DD-MM-YYYY")} to ${moment(
              endDate
            ).format("DD-MM-YYYY")}`,
            600,
            30
          );
        }

        doc.text(`M/S: ${clientName}`, 40, 50);
      }
      // Footer
      let footerStr = `Page ${data.pageCount}`;
      doc.text(
        footerStr,
        data.settings.margin.left,
        doc.internal.pageSize.height - 10
      );
    },
    // Cell styles configuration
    headStyles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      fontStyle: "bold",
    }, // White header with black text
    bodyStyles: { textColor: [0, 0, 0] }, // Black text for body cells
    alternateRowStyles: { fillColor: [240, 240, 240] }, // Light grey for alternating rows
    columnStyles: {
      // Apply borders to all cells
      0: { lineWidth: 0.1, lineColor: [0, 0, 0] },
      1: { lineWidth: 0.1, lineColor: [0, 0, 0] },
      2: { lineWidth: 0.1, lineColor: [0, 0, 0] },
      3: { lineWidth: 0.1, lineColor: [0, 0, 0] },
      4: { lineWidth: 0.1, lineColor: [0, 0, 0] },
      5: { lineWidth: 0.1, lineColor: [0, 0, 0] },
    },
  });

  console.log("tableData>>>>",`${tableData[tableData.length - 1][5]}`)

  let closingBalanceRow = [
    "",
    "",
    "",
    "",
    "",
    `Closing Balance:${tableData[tableData.length - 1][5]}`,
  ];

  doc.autoTable({
    startY: doc.lastAutoTable.finalY + 0,
    showHead: "never",
    margin: { top: 2 },
    head: [["Sr No.", "Date", "Codes", "Credit", "Debit", "Balance"]],
    body: [closingBalanceRow],
    styles: { fontSize: 12, cellPadding: 5, fontStyle: "bold" },
    theme: "plain",
    tableLineColor: [0, 0, 0], // Black borders
    tableLineWidth: 0.1,
    columnStyles: {
      5: { halign: "right" }, // Right align the last column (index 5)
      4: { halign: "right" }, // Right align the last column (index 5)
    },
  });

  const pdfBlob = doc.output("blob");

  // Open the PDF in a new browser tab
  const url = window.URL.createObjectURL(pdfBlob);
  window.open(url, "_blank");
  // return doc.save('ledger.pdf');
};
