import React, { useEffect, useState } from "react";
// @material-ui/core components
import {
  Box,
  Dialog,
  Fab,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
// core components
import DateUtils from "@date-io/moment";

import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import axios from "axios";
import {
  CreateBankAPI,
  CreatePaymentAPI,
  GetAllAccountsAPI,
  GetAllBillsAPI,
  GetAllChallansAPI,
  UpdateBillAPI,
  UpdateChallanAPI,
  GetPartyPurchasesAPI,
} from "../../config/api";
import { Autocomplete } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import _ from "lodash";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import moment from "moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const styles = {
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);
const columns = [
  { id: "paymentMode", label: "Payment Mode" },
  { id: "paymentAmount", label: "Payment Amount*" },
  // {id: 'createdAt', label: 'Created At', format: value => value ? moment(value).format('DD-MM-YYYY') : ''},
  { id: "action", label: "Action" },
];
const paymentModes = ["Cheque", "Cash and Direct"];
let paymentTypes = [
  {
    label: "Challan",
    value: "challanSale",
    partyType: "sale",
  },
  {
    label: "Bill",
    value: "billSale",
    partyType: "sale",
  },
  {
    label: "Challan",
    value: "challanPurchase",
    partyType: "purchase",
  },
  {
    label: "Bill",
    value: "billPurchase",
    partyType: "purchase",
  },

];

export default function PaymentAdd(props) {
  const classes = useStyles();
  const theme = useTheme();
  const userInfo = useSelector((state) => state.userReducer.userInfo);
  const authToken = useSelector((state) => state.userReducer.authToken);

  const { open, close, fetchData } = props;
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [bills, setBills] = useState([]);
  const [selectedBills, setSelectedBills] = useState(null);
  const [challans, setChallans] = useState([]);
  const [selectedChallans, setSelectedChallans] = useState(null);
  const [payments, setPayments] = useState([]);
  const [paymentMode, setPaymentMode] = useState("");
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [chequeNo, setChequeNo] = useState("");
  const [chequeDate, setChequeDate] = useState("");
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentDetails, setPaymentDetails] = useState("");
  const [refNo, setRefNo] = useState(Math.floor(Math.random() * 1000000000));
  const [accounts, setAccounts] = useState([]);
  const [selectedPartyType, setSelectedPartyType] = useState(null);
  const [selectedParty, setSelectedParty] = useState(null);
  const [paymentType, setPaymentType] = useState("");
  console.log(">>>>", selectedBills)
  const [runningBalance, setRunningBalance] = useState("");
  const [totalBalance, setTotalBalance] = useState("");
  const [purchase, setPurchase] = useState([]);
  const [paymentDate, setPaymentDate] = useState(null)
  const [error, setError] = useState('');
const [disableSaveButton, setDisableSaveButton] = useState(false);

  useEffect(() => {
    _getAccounts();
  }, [selectedPartyType]);

  useEffect(() => {
    if (selectedParty) {
      let rb = 0
      console.log(rb, "<<<<<<<<<runningBalance")
      if (paymentType === "challanSale") {
        rb = Number(selectedParty.runningChallanBalance + selectedParty.openingChallanBalance)
        setTotalBalance(_.round(rb, 2));
        setRunningBalance(_.round(Number(selectedParty.runningChallanBalance), 2));
      } else if (paymentType === "billSale") {
        rb = Number(selectedParty.runningBillBalance + selectedParty.openingBillBalance)
        setTotalBalance(_.round(rb, 2));
        setRunningBalance(_.round(Number(selectedParty.runningBillBalance), 2));
      } else if (paymentType === "challanPurchase") {
        rb = Number(selectedParty.runningChallanBalance + selectedParty.openingChallanBalance)
        setTotalBalance(_.round(rb, 2));
        setRunningBalance(_.round(Number(selectedParty.runningChallanBalance), 2));
      } else if (paymentType === "billPurchase") {
        rb = Number(-selectedParty.runningBillBalance + selectedParty.openingBillBalance)
        setTotalBalance(_.round(rb, 2));
        setRunningBalance(_.round(Number(selectedParty.runningBillBalance), 2));
      }
    }
  }, [paymentType]);

  useEffect(() => {
    if (selectedParty) {
      _getChallans();
      _getBills();
      _getPurchase();
    }
  }, [selectedParty]);

  useEffect(() => {
    if (selectedChallans) {
      setSelectedBills(null);
    } else {
      setSelectedChallans(null);
    }
  }, [selectedChallans]);

  useEffect(() => {
    if (selectedBills) {
      setSelectedChallans(null);
    } else {
      setSelectedBills(null);
    }
  }, [selectedBills]);

  async function _getPurchase() {
    try {
      let res = await axios.get(GetPartyPurchasesAPI(selectedParty._id), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setPurchase(res.data.purchase);
    } catch (err) {
      console.log(err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  console.log(challans, "My challans in payment add")

  const handlePaymentChange = (event) => {
    const value = event.target.value;
    
    // Validate the payment amount
    if (parseFloat(value) > runningBalance) {
      setPaymentAmount(0);
      // setDisableSaveButton(true)
      setError(`Payment amount cannot exceed running balance of ${runningBalance}`);
    } else {
      setError('');
    }

    setPaymentAmount(value);
  };

  async function _getAccounts() {
    try {
      let res = await axios.get(
        GetAllAccountsAPI(userInfo.entity),
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      let accounts = res.data.accounts.filter(account => account.partyType === selectedPartyType);
      setAccounts(accounts);
    } catch (err) {
      // console.log(err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _getBills() {
    try {
      let query = `&userId=${selectedParty._id}&paymentStatus=Pending`;
      let res = await axios.get(GetAllBillsAPI(userInfo.entity) + query, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setBills(res.data.bills);
    } catch (err) {
      // console.log("error:", err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _getChallans() {
    try {
      let query = `&userId=${selectedParty._id
        }&paymentStatus=Pending&isBilled=${false}`;
      let res = await axios.get(GetAllChallansAPI(userInfo.entity) + query, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setChallans(res.data.challans);
    } catch (err) {
      // console.log("error:", err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _onSave() {
    try {
      let amount = 0,
        code = "",
        paymentFor = "",
        paidAmount = 0;
      amount = Number(paymentAmount);
      if (paymentType === "challanSale") {
        paymentFor = "Sale Challan";
      }
      if (paymentType === "billSale") {
        paymentFor = "Sale Bill";
      }
      if (paymentType === "challanPurchase") {
        paymentFor = "Purchase Challan";
      }
      if (paymentType === "billPurchase") {
        paymentFor = "Purchase Bill";
      }

      let payment = {
        paymentFor,
        party: {
          _id: selectedParty._id,
          accountCode: selectedParty.accountCode,
          partyType: selectedParty.partyType,
          name: selectedParty.name,
        },
        amount,
        entity: userInfo.entity,
        paymentDate,
        payments: {
          paymentMode,
          paymentAmount,
          chequeNo,
          chequeDate,
          bankName,
          branchName,
          paymentMethod,
          refNo,
          createdAt: new Date(),
        },
        paymentDetails,
      };
      if (
        selectedParty === null ||
        paymentType === "" ||
        runningBalance === "" ||
        totalBalance === "" ||
        paymentMode === "" ||
        paymentAmount === "" ||
        paymentMode === "Cash and Direct" && paymentMethod === "" ||
        paymentDetails === "" ||
        paymentMode === "Cheque" && chequeNo === "" ||
        paymentMode === "Cheque" && chequeDate === "" ||
        paymentMode === "Cheque" && bankName === "" ||
        paymentMode === "Cheque" && branchName === ""
      ) {
        toast.error('Please fill all the required fields!', {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
        console.log("check type", paymentType)
      }

      let res = await axios.post(CreatePaymentAPI(), payment, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      toast.success('Successfully Added!', {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      close();
      window.location.reload();
      // fetchData();
    } catch (e) {
      // console.log("err:", e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={close}
      aria-labelledby="responsive-dialog-title"
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Add Payment</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Autocomplete
                    id="partyType"
                    className={classes.formControl}
                    options={['sale', 'purchase']}
                    value={selectedPartyType}
                    onChange={(event, value) => setSelectedPartyType(value)}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Party Type*" />
                    )}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Autocomplete
                    id="party"
                    className={classes.formControl}
                    options={accounts}
                    value={selectedParty}
                    onChange={(event, value) => setSelectedParty(value)}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Party*" />
                    )}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Payment Type*
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={paymentType}
                      label="Type"
                      onChange={(e) => {
                        e.preventDefault();
                        setPaymentType(e.target.value);
                      }}
                    >
                      {paymentTypes.map(({ label, value, partyType }) => {
                        if (
                          selectedParty &&
                          selectedParty.partyType === partyType
                        ) {
                          return <MenuItem value={value}>{label}</MenuItem>;
                        }
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                {selectedParty ? (
                  <>
                    {/* {paymentType === "challan" ? (
                      <>
                        <GridItem xs={12} sm={12} md={12}>
                          <Autocomplete
                            id="challans"
                            className={classes.formControl}
                            options={challans}
                            value={selectedChallan}
                            onChange={(event, value) => setSelectedChallan(value)}
                            getOptionLabel={(option) => option.code}
                            renderInput={(params) => (
                              <TextField {...params} label="Select Challan" />
                            )}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <TextField
                            label="Challan Price"
                            fullWidth
                            disabled
                            value={selectedChallan?.amount ? selectedChallan?.amount : 0}
                          />
                        </GridItem>
                      </>
                    ) : null} */}

                    {/* {paymentType === "bill" ? (
                      <>
                        <GridItem xs={12} sm={12} md={12}>
                          <Autocomplete
                            id="bills"
                            className={classes.formControl}
                            options={bills}
                            value={selectedBills}
                            onChange={(event, value) => setSelectedBills(value)}
                            getOptionLabel={(option) => option.code}
                            renderInput={(params) => (
                              <TextField {...params} label="Select Bill" />
                            )}
                          />
                        </GridItem>
                        
                      </>
                    ) : null} */}
                  </>
                ) : null}

                {/* <GridItem xs={12} sm={12} md={12}>
                  <Autocomplete
                    // multiple
                    id="bills"
                    className={classes.formControl}
                    options={bills}
                    value={selectedBills}
                    onChange={(event, value) => {
                      setSelectedBills(value);
                    }}
                    getOptionLabel={(option) => option.code}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Bills" />
                    )}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Autocomplete
                    id="challans"
                    className={classes.formControl}
                    options={challans}
                    value={selectedChallans}
                    onChange={(event, value) => {
                      setSelectedChallans(value);
                    }}
                    getOptionLabel={(option) =>
                      option.code ? option.code : ""
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Select Challans" />
                    )}
                  />
                </GridItem> */}

                <GridItem xs={12} sm={12} md={12}>
                  <MuiPickersUtilsProvider utils={DateUtils}>
                    <DatePicker
                      label="Payment Date"
                      fullWidth
                      value={paymentDate}
                      onChange={(date) => setPaymentDate(date)}
                      animateYearScrolling
                    // style={{marginTop:5}}
                    />
                  </MuiPickersUtilsProvider>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <CustomInput
                    labelText="Running Amount*"
                    id="runningAmount"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      value: totalBalance,
                      onChange: (event) =>
                        setTotalBalance(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <Autocomplete
                    id="paymentMode"
                    className={classes.formControl}
                    options={paymentModes}
                    value={paymentMode}
                    onChange={(event, value) => setPaymentMode(value)}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Payment Mode*" />
                    )}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>

      <CustomInput
        labelText="Payment Amount*"
        id="paymentAmount"
        formControlProps={{
          fullWidth: true,
        }}
        value={paymentAmount}
        inputProps={{
          type: "number",
          value: paymentAmount,
          onChange: handlePaymentChange,
        }}
      />
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </GridItem>

                {paymentMode ? (
                  paymentMode === paymentModes[0] ? (
                    <>
                      <GridItem xs={6} sm={6} md={6}>
                        <CustomInput
                          labelText="Cheque No.*"
                          id="chequeNo"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: chequeNo,
                            onChange: (event) =>
                              setChequeNo(event.target.value),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={6} sm={6} md={6}>
                        <CustomInput
                          labelText="Cheque Date*"
                          id="chequeDate"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: chequeDate,
                            onChange: (event) =>
                              setChequeDate(event.target.value),
                          }}
                        />
                      </GridItem>

                      <GridItem xs={6} sm={6} md={6}>
                        <CustomInput
                          labelText="Bank Name*"
                          id="bankName"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: bankName,
                            onChange: (event) =>
                              setBankName(event.target.value),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={6} sm={6} md={6}>
                        <CustomInput
                          labelText="Branch Name*"
                          id="branchName"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: branchName,
                            onChange: (event) =>
                              setBranchName(event.target.value),
                          }}
                        />
                      </GridItem>
                    </>
                  ) : (
                    <>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Payment Method"
                          id="paymentMethod"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: paymentMethod,
                            onChange: (event) =>
                              setPaymentMethod(event.target.value),
                          }}
                        />
                      </GridItem>
                      {/* <GridItem xs={6} sm={6} md={6}>
                        <CustomInput
                          labelText="Ref No."
                          id="refNo"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: refNo,
                            onChange: (event) => setRefNo(event.target.value),
                          }}
                        />
                      </GridItem> */}
                    </>
                  )
                ) : null}
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Payment Details*"
                    id="paymentDetails"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: paymentDetails,
                      onChange: (event) =>
                        setPaymentDetails(event.target.value),
                    }}
                  />
                </GridItem>

                {_.isEmpty(payments) ? null : (
                  <GridItem xs={12}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {payments.map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              {columns.map((column) => {
                                let value = row[column.id];
                                if (column.id === "action") {
                                  return (
                                    <TableCell align={"center"}>
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                      >
                                        <IconButton
                                          aria-label="delete"
                                          onClick={() => {
                                            let copy = _.clone(payments);
                                            copy.splice(index, 1);
                                            setPayments(copy);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    </TableCell>
                                  );
                                }
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </GridItem>
                )}
              </GridContainer>
            </CardBody>
            <CardFooter style={{ justifyContent: "center" }}>
              <Button disabled={disableSaveButton} color="primary" onClick={() => _onSave()}>
                Add Payment
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}
