import React, { useState, useEffect } from "react";
import axios from "axios";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import { ChallanList } from "../Challan/ChallanList";
import { BillList } from "../Bill/BillList";
import classNames from "classnames";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import { Box, Dialog, makeStyles, Slide, Tab, Tabs, TextField } from "@material-ui/core";
import CustomInput from "../../components/CustomInput/CustomInput";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import CardBody from "../../components/Card/CardBody";
import styles from "../../assets/jss/material-dashboard-react/components/customTabsStyle";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { FilterDialog } from "../../components/common/FilterDialog";
import { GetAllAccountsAPI, GetBulkReadyMasterRangeAPI, GetAllBrandsAPI, GetAllReadyMastersAPI, GetStockReportAPI } from "../../config/api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ChallanListCredit } from "../Challan/ChallanListCredit";
import { Search } from "@material-ui/icons";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import Autocomplete from "@mui/material/Autocomplete";
import { StockList } from "./StockList";
import PrintIcon from "@mui/icons-material/Print";
import StockListPageM from "../../assets/html/StockListPageM";
import { dataURItoBlob, htmlTemplateToDataString } from "../../utils";
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

const tabs = [
    {
        tabName: "Challans",
        tabIcon: BugReport,
        tabContent: <ChallanListCredit />,
    },
];

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Test(props) {
    const [brands, setBrands] = useState([]);
    const [value, setValue] = useState(0);
    const authToken = useSelector((state) => state.userReducer.authToken);
    const userInfo = useSelector((state) => state.userReducer.userInfo);
    const [product, setProduct] = useState('');
    const [brand, setBrand] = useState('');
    const [category, setCategory] = useState('');
    const [searchTerm, setSearchTerm] = useState("");
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState(null);
    const [openFilter, setOpenFilter] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [party, setParty] = useState([]);
    const [dataaa, setDataaa] = useState([])
    const [tota, setTota] = useState(0);
    const [dataToPass, setDatatoPass] = useState({})

    const classes = useStyles();
    const { rtlActive } = props;
    const cardTitle = classNames({
        [classes.cardTitle]: true,
        [classes.cardTitleRTL]: rtlActive,
    });
    const [readyMasterRange, setReadyMasterRange] = useState([]);
    const filterOptions = createFilterOptions({
        matchFrom: "any",
        limit: 500,
    });



    useEffect(() => {
        console.log(dataaa, "dd");
    })

    function getAllThings() {
        Promise.all([
            axios.get(GetAllAccountsAPI(userInfo.entity), {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            }),
            axios.get(GetBulkReadyMasterRangeAPI(userInfo.entity), {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            }),
            axios.get(GetAllBrandsAPI(userInfo.entity), {
                headers: {
                    Authorization: `Bearer ${authToken}`
                }
            })
        ]).then((responses) => {
            const [accountsResponse, readyMasterRangeResponse, brands] = responses;

            // Handle accounts data
            const accountsData = accountsResponse.data.accounts;
            setAccounts(accountsData);
            const partyData = accountsData.map((account) => account.name);
            setParty(partyData);

            // Handle readyMasterRange data
            const readyMasterRangeData = readyMasterRangeResponse.data.readyMasterRange;
            setReadyMasterRange(readyMasterRangeData);

            //HAndle brands

            const brandsData = brands.data.brandMasters
            setBrands(brandsData)
        }).catch((error) => {
            console.error("Error fetching data:", error);
            toast.error("Something went wrong, Please Contact Admin", {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        });
    }

    useEffect(() => {
        getAllThings();
    }, [authToken, userInfo.entity]);


    const handleChange = (event, value) => {
        setValue(value);
    };

    const handleCloseFilterModal = () => {
        setOpenFilter(false);
    };


    function handleSearch() {
        setDatatoPass({
            "category": category,
            "brand": brand,
            "product": product
        })
    }

    async function _onPreview() {
        try {
            const doc = new jsPDF();

            const tableData = dataaa.map(item => [
                `${item.productName} [${item.productCode}]`, // Combine productName and productCode into one cell
                item.threshold,
                item.quantity,
                item.threshold - item.quantity
            ]);

            // Set font size and style
            doc.setFontSize(12); // Adjust font size as needed
            doc.setFontStyle('bold'); // Set font style to bold

            // Add Stock Report text
            const textWidth = doc.getStringUnitWidth('Stock Report') * 12 / doc.internal.scaleFactor; // Adjust font size here too
            const xPosition = (doc.internal.pageSize.getWidth() - textWidth) / 2;
            doc.text('Stock Report', xPosition, 5); // Adjust Y position as needed

            // Add table with the provided styling
            doc.autoTable({
                head: [['Product Code', 'Threshold', 'Quantity', 'Order']],
                body: tableData,
                startY: 10, // Adjust Y position to start table below the title
                styles: {
                    fontSize: 10,
                    cellPadding: { top: 2, right: 2, bottom: 2, left: 2 },
                    textColor: [0, 0, 0],
                    lineWidth: 0.1,
                    lineColor: [0, 0, 0],
                    fillColor: [255, 255, 255]
                },
                headStyles: {
                    fontStyle: 'bold',
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    valign: 'middle',
                    lineWidth: 0.1,
                    lineColor: [0, 0, 0],
                    fontSize: 8,
                },
                footStyles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    lineWidth: 0.1,
                    lineColor: [0, 0, 0]
                },
                tableLineWidth: 0.1,
                tableLineColor: [0, 0, 0],
                columnStyles: {
                    0: { cellWidth: 'auto' }, // Product Code
                    1: { cellWidth: 'auto' }, // Threshold
                    2: { cellWidth: 'auto' }, // Quantity
                    3: { cellWidth: 'auto' }, // Order
                },
                didDrawPage: function (data) {
                    // Footer
                    let footerStr = `Page ${data.pageCount}`;
                    doc.text(footerStr, data.settings.margin.left, doc.internal.pageSize.height - 10);
                }
            });

            // Output the PDF as a Blob
            const pdfBlob = doc.output('blob');

            // Open the PDF in a new browser tab
            const url = window.URL.createObjectURL(pdfBlob);
            window.open(url, '_blank');

        } catch (error) {
            console.error("Error generating PDF:", error);
        }
    }







    return (
        <Card plain={false}>
            <CardHeader color={"rose"} plain={false}>
                <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                    <div>
                        {/* <Tabs
                            value={value}
                            onChange={handleChange}
                            classes={{
                                root: classes.tabsRoot,
                                indicator: classes.displayNone,
                                scrollButtons: classes.displayNone,
                            }}
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            {tabs.map((prop, key) => {
                                let icon = {};
                                if (prop.tabIcon) {
                                    icon = {
                                        icon: <prop.tabIcon />,
                                    };
                                }
                                return (
                                    <Tab
                                        classes={{
                                            root: classes.tabRootButton,
                                            selected: classes.tabSelected,
                                            wrapper: classes.tabWrapper,
                                        }}
                                        key={key}
                                        label={prop.tabName}
                                        {...icon}
                                    />
                                );
                            })}
                        </Tabs> */}
                    </div>
                    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', gap: 50 }} >
                        <Autocomplete
                            disablePortal
                            id="category-autocomplete"
                            options={['color', 'Range']}
                            fullWidth
                            value={category}
                            onChange={(event, value) => setCategory(value)}
                            renderInput={(params) => <TextField {...params} label="Category"
                                style={{ backgroundColor: '#FFFFFF', opacity: '50%', borderRadius: 5 }}
                            />}
                        />

                        <Autocomplete
                            id="product"
                            filterOptions={filterOptions}
                            loading={readyMasterRange.length === 0 ? true : false}
                            className={classes.formControl}
                            options={readyMasterRange}
                            fullWidth
                            onChange={(event, value) => {
                                // Check if any product already has a discount
                                setProduct(value ? value.productName : '');
                            }}
                            getOptionLabel={(option) => `${option.productName}`}
                            renderInput={(params) => (
                                <TextField {...params} label="Select Product"
                                    style={{ backgroundColor: '#FFFFFF', opacity: '50%', borderRadius: 5 }}
                                />
                            )}
                        />

                        <Autocomplete
                            disablePortal
                            id="brand-autocomplete"
                            options={brands}
                            fullWidth
                            onChange={(event, value) => setBrand(value ? value._id : '')}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="Brands"
                                style={{ backgroundColor: '#FFFFFF', opacity: '50%', borderRadius: 5 }}
                            />}
                        />
                        <IconButton color={"secondary"} onClick={(e) => handleSearch()}>
                            <Search />
                        </IconButton>
                        <IconButton color={"secondary"} onClick={() => _onPreview()}>
                            <PrintIcon />
                        </IconButton>

                    </Box>
                </Box>
            </CardHeader>
            <CardBody>
                <StockList filter={filter} accounts={accounts} readyMasterRange={readyMasterRange} dataToPass={dataToPass} setDataaa={setDataaa} />
            </CardBody>
            <FilterDialog
                party={party}
                filter={filter}
                setFilter={setFilter}
                showFilterDialog={openFilter}
                handleCloseFilterDialog={handleCloseFilterModal}
            />
        </Card>
    );
}
