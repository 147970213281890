import moment from "moment"
import { inWords, inWords2 } from "../../utils"


export const readyOrderTemplate = (d,) => {
  console.log(d, "Dattt")

  return `
  <!DOCTYPE html>
  <html>
  
  <head>
    <style>
      body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      }
  
  
      table {
        width: 280px;
        font-size:13px;
      }
      .last {
        border-bottom: 1px solid black;
        padding-bottom:5px
    }

    </style>
  </head>
  
  <body>
  <div style=marginBottom: 10  />
  <style>
      .text-center{
          text-align:center;  
          margin-left:100px;
      }
  </style>
  <div className="text-center" >
    <table className="table" >
      <thead>
        <tr>
          <th colSpan="3" className="text-center">
            Order # : ${d.code.substring(0, d.code.length - 6)}
          </th>
          <th colSpan="3" className="text-center">
            Party Reference : ${d.orderDetail.partyRef ? d.orderDetail.partyRef : ''}
          </th>
          </tr>

          <tr>
          <th colSpan="6" className="text-center">
          Party  : ${d.party.accountName ? d.party.accountName : ''}
        </th>
          </tr>
          <tr>
          <th colSpan="6" className="text-center">
          Product  : ${d.orderDetail.product.productName ? d.orderDetail.product.productName : ''}
        </th>
          </tr>
          <tr class="last">
          <th colSpan="3" className="text-center">
            Quanity: ${d.orderDetail.quantity}
          </th>
          <th colSpan="3" className="text-center">
            Date: ${moment(d.createdAt).format("DD/MM/YY")}
          </th>
        </tr>
      </thead>
      <tbody>

        <tr>
          <th colSpan="1" className="text-center">
            SIDE
          </th>
          <th colSpan="1" className="text-center">
            SPH
          </th>
          <th colSpan="1" className="text-center">
            CYL
          </th>
          <th colSpan="1" className="text-center">
            AXIS
          </th>
          <th colSpan="1" className="text-center">
            ADD
          </th>
        </tr>
        <tr>
          <th colSpan="1" className="text-center">
            R
          </th>
          <td colSpan="1" className="text-center"   style="text-align:center;" >
            ${d.orderDetail.rSph}
          </td>
          <td colSpan="1" className="text-center"   style="text-align:center;">
            ${d.orderDetail.rCyl}
          </td>
          <td colSpan="1" className="text-center"   style="text-align:center;">
            ${d.orderDetail.rAxis}
          </td>
          <td colSpan="1" className="text-center"  style="text-align:center;">
            ${d.orderDetail.rAddition}
          </td>
        </tr>
        <tr class="last">
          <th colSpan="1" className="text-center">
            L
          </th>
          <td colSpan="1" className="text-center"  style="text-align:center;" >
            ${d.orderDetail.lSph}
          </td>
          <td colSpan="1" className="text-center"   style="text-align:center;">
            ${d.orderDetail.lCyl}
          </td>
          <td colSpan="1" className="text-center"   style="text-align:center;">
            ${d.orderDetail.lAxis}
          </td>
          <td colSpan="1" className="text-center"   style="text-align:center;">
            ${d.orderDetail.lAddition}
          </td>
        </tr>
        <tr>
        <td colSpan="4" className="text-center">
        <b>Order Note : </b>
        ${d?.orderNotes}
      </td>
        </tr>
      </tbody>
    </table>
    <br />
    <br />
   
  </div>
  </body>
  
  </html>
`


}

export const fxOrderTemplate = (d,) => {
  console.log(d, "Dattt")

  return `
  <!DOCTYPE html>
  <html>
  
  <head>
    <style>
      body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      }
  
  
      table {
        width: 280px;
        font-size:14px;
      }
      .last {
        border-bottom: 1px solid black;
        padding-bottom:5px
    }

    </style>
  </head>
  
  <body>
  <div style=marginBottom: 10  />
  <style>
      .text-center{
          text-align:center;  
          margin-left:100px;
      }
  </style>
  <div className="text-center" >
    <table className="table" >
      <thead>
        <tr class="last">
          <th colSpan="1" className="text-center">
            Order # : ${d.code.substring(0, d.code.length - 6)}
          </th>
          <th colSpan="3" className="text-center">
            Party Reference : ${d.orderDetail.partyRef}
          </th>
          <th colSpan="1" className="text-center">
            Brand : ${d.orderDetail.lab}
          </th>
        </tr>
        <hr/>
      </thead>
      <tbody>
      <tr>
      <th colSpan="6" className="text-center">
        Date : ${moment(d.createdAt).format("DD/MM/YY")}
      </th>
    </tr>
        <tr>
          <th colSpan="6" className="text-center">
            Optician : ${d.party.accountName}
          </th>
        </tr>
        <tr>
        <th colSpan="6" className="text-center">
        Customer Name : ${d.isOrderFromApp ? d.orderDetail.customerName : d.orderDetail.clientName}
      </th>
        </tr>
        <tr>
          <th colSpan="3" className="text-center">
            Index : ${d.orderDetail.index}
          </th>
          <th colSpan="3" className="text-center">
            Glass : ${d.orderDetail.glassType}
          </th>
         
        </tr>
        <tr class="last">
        <th colSpan="3" className="text-center">
        Product : ${d.orderDetail.productName}
      </th>
      <th colSpan="3" className="text-center">
        Coat : ${d.orderDetail.coat}
      </th>
        </tr>
        <hr/>
        <tr>
          <th colSpan="1" className="text-center">
            SIDE
          </th>
          <th colSpan="1" className="text-center">
            SPH
          </th>
          <th colSpan="1" className="text-center">
            CYL
          </th>
          <th colSpan="1" className="text-center">
            AXIS
          </th>
          <th colSpan="1" className="text-center">
            ADD
          </th>
        </tr>
        <tr>
          <th colSpan="1" className="text-center">
            R
          </th>
          <td colSpan="1" className="text-center"   style="text-align:center;" >
            ${d.orderDetail.rSph}
          </td>
          <td colSpan="1" className="text-center"   style="text-align:center;">
            ${d.orderDetail.rCyl}
          </td>
          <td colSpan="1" className="text-center"   style="text-align:center;">
            ${d.orderDetail.rAxis}
          </td>
          <td colSpan="1" className="text-center"  style="text-align:center;">
            ${d.orderDetail.rAddition}
          </td>
        </tr>
        <tr class="last">
          <th colSpan="1" className="text-center">
            L
          </th>
          <td colSpan="1" className="text-center"  style="text-align:center;" >
            ${d.orderDetail.lSph}
          </td>
          <td colSpan="1" className="text-center"   style="text-align:center;">
            ${d.orderDetail.lCyl}
          </td>
          <td colSpan="1" className="text-center"   style="text-align:center;">
            ${d.orderDetail.lAxis}
          </td>
          <td colSpan="1" className="text-center"   style="text-align:center;">
            ${d.orderDetail.lAddition}
          </td>
        </tr>
        <tr>
          <th colSpan="3" className="text-center">
            ED : ${d.orderDetail.ed}
          </th>
          <th colSpan="3" className="text-center">
            PD : ${d.orderDetail.pd}
          </th>
        
         
        </tr>
        <tr>
        <th colSpan="3" className="text-center">
        PRISM : ${d.orderDetail.prism}
      </th>
      <th colSpan="3" className="text-center">
        DIA : ${d.orderDetail.dia}
      </th>
        </tr>
        <tr>
        <th colSpan="2" className="text-center">
        FTH : ${d.orderDetail.fittingHeight}
      </th>
          <th colSpan="2" className="text-center">
            Frame : ${d.orderDetail.frameSection}
          </th>
          <th colSpan="2" className="text-center">
            Pair : ${d.orderDetail.quantity}
          </th>
        </tr>
        <tr>
          <td colSpan="2" className="text-center">
            <b>Remark : </b>
            ${d.orderDetail.note}
          </td>
         
 
          
        </tr>
        <tr >
        <td colSpan="3" className="text-center">
        <b>Frame Image : </b>
        ${d.frameImage !== "" ? "Yes" : "No"}
        </td>
        <td colSpan="3" className="text-center">
        <b>Tint Image : </b>
        ${d.tintImage !== "" ? "Yes" : "No"}
      </td>
        </tr>

      </tbody>
    </table>
    <br />
    <br />
   
  </div>
  </body>
  
  </html>
`


}


export const paymentSlipTemplate = (d,) => {
  console.log(d, "Dattt")

  let totalAmountInWords = inWords2(Number(d.amount));
  let amount = 0

  return `
  <!DOCTYPE html>
  <html>
  
  <head>
    <style>
      p {
        font-size:12px;
        line-height:3px;
      }
      table{
        margin:0px;
      }
    </style>  
  </head>
  
  <body>
  <div style="width: 580px; margin: 0 auto; padding: 5px; ">

  <div style="text-align: center;">
    <h3>Payment Receipt - ${d.code}</h3>
    <p>Date: <span>${moment(d.paymentDate).format("LL")}</span></p>
  </div>

  <div>
    <h3>Customer Details:</h3>
    <h4><strong>Name:</strong> ${d.party.name}</h4>
  </div>

  <div>
    <h3>Payment Details:</h3>
    <table style="width: 100%; border-collapse: collapse; ">
      <tr>
        <th style="border: 1px solid #ccc; text-align: left;">Payment Mode</th>
        <th style="border: 1px solid #ccc; text-align: left;">Amount</th>
      </tr>
      ${d.payments.map((m) => {
    amount += Number(m.paymentAmount)
    return (
      `
        <tr>
        <td style="border: 1px solid #ccc;">${m.paymentMode}
        ${m.paymentMode === "Cheque" ? (
        `
          <br/>
          <p>Cheque Number - ${m.chequeNo}</p>
          <p>Bank Name - ${m.bankName}</p>
          <p>Branch Name - ${m.branchName}</p>
          `
      ) : ''}
        </td>
        <td style="border: 1px solid #ccc;">${m.paymentAmount}</td>
        </tr>
        `
    )
  })}

      <tr>
        <td style="border: 1px solid #ccc; "><strong>Total</strong></td>
        <td style="border: 1px solid #ccc; "><strong>${amount}</strong></td>
      </tr>
    </table>
    <h4>Total Amount in words : ${inWords2(amount)}</h4>
    <div style="display:flex; flex-direction:row;justify-content:space-between ; padding-horizontal:50px">
    <h5 style="width:200px;">Note <br/> ${d.paymentDetails} </h5>
    <h5>Signature :</h5>
    </div>
  </div>

</div>

  </body>
  
  </html>
`


}